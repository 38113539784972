import React, { useState } from "react";
import Select from "react-select";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "../../fbase";
import { debounce } from "lodash";
import axios from "axios";

const STORAGE_URL = "https://storage.cryucard.com";

const musicArr = ["/basic/1.mp3", "/basic/2.mp3"];
const options = [
  { value: "", label: "추가 안함" },
  { value: "0", label: "잔잔한 BGM" },
  { value: "1", label: "경쾌한 BGM" },
  { value: "upload", label: "음원 파일 업로드" },
];

const FILE_SIZE_MAX_LIMIT = 5 * 1024 * 1024; // 5MB

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    cursor: "pointer",
  }),
  control: (styles) => ({
    ...styles,
    cursor: "pointer",
    height: 50,
  }),
};

export default function BackgroundMusicComponent({ state, getData, letterId }) {
  // s3설정
  const config = {
    bucketName: "new-cryucard",
    dirName: `bgm/${state.weddingDate ? state.weddingDate : "normal"}`,
    region: "ap-northeast-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);
  // s3설정

  const [selectedOption, setSelectedOption] = useState(
    state.bgmOption !== undefined
      ? state.bgmOption
      : {
          value: "",
          label: "추가 안함",
        }
  );

  const [isAuto, setIsAuto] = useState(
    state.isAuto !== undefined ? state.isAuto : true
  );
  const [isPlay, setIsPlay] = useState(false);

  const [bgmUrl, setBgmUrl] = useState(state.bgmUrl ? state.bgmUrl : "");

  const fileInput = React.useRef(null);

  const myRef = React.useRef();

  const startAudio = () => {
    myRef.current.play();

    setIsPlay(true);
  };

  const pauseAudio = () => {
    console.log("here");
    myRef.current.pause();
    setIsPlay(false);
  };

  const bgmHandleChange = (_selectedOption) => {
    const { value } = _selectedOption;
    setSelectedOption(_selectedOption);
    onSubmit({ _bgmOption: _selectedOption });

    if (value === "upload") {
      setBgmUrl(state.bgmUrl ? state.bgmUrl : "");
      fileInput.current.click();
      setIsPlay(false);
    } else if (value === "") {
      setBgmUrl("");
      onSubmit({ _bgmUrl: "" });

      setIsPlay(false);
    } else {
      setBgmUrl(`${STORAGE_URL}/bgm${musicArr[value * 1]}`);
      onSubmit({ _bgmUrl: musicArr[value * 1] });
      setIsPlay(false);
    }
  };

  // const uploadBgm = async (_uploadBgm) => {
  //   console.log(_uploadBgm);
  //   if (_uploadBgm) {
  //     await ReactS3Client.uploadFile(_uploadBgm, `${letterId}-${uuidv4()}`)
  //       .then((data) => {
  //         console.log(data.location.split("bgm"));
  //         onSubmit({ _bgmUrl: data.location.split("bgm")[1] });
  //       })
  //       .catch((err) => console.error(err));
  //   }
  // };

  const uploadBgm = async (_uploadBgm) => {
    if (!_uploadBgm) return;

    try {
      // Step 1: Firebase Function 호출하여 Signed URL 요청
      const fileType = _uploadBgm.type; // BGM 파일 타입 (예: audio/mp3)
      const fileName = _uploadBgm.name; // BGM 파일 이름

      const response = await axios.post(
        "https://us-central1-self-cryucard.cloudfunctions.net/api/upload-bgm-to-s3",
        {
          fileName,
          fileType,
          date: state.weddingDate ? state.weddingDate : "normal",
          userId: letterId, // letterId를 userId로 사용
        }
      );

      const { uploadUrl, s3Key } = response.data;

      // Step 2: S3에 BGM 파일 업로드
      await axios.put(uploadUrl, _uploadBgm, {
        headers: {
          "Content-Type": fileType,
        },
      });

      // Step 3: 업로드된 BGM 경로 처리
      const submitBgmUrl = s3Key.split("bgm")[1];
      onSubmit({ _bgmUrl: submitBgmUrl });

      console.log("BGM uploaded successfully:", submitBgmUrl);
    } catch (error) {
      console.error("Error uploading BGM:", error);
    }
  };

  // let song = new Audio(_bgm);

  const onSubmit = async ({ _bgmUrl, _bgmOption, _isAuto }) => {
    let content = {};

    if (_bgmUrl !== undefined) {
      content = {
        bgmUrl: _bgmUrl === "" ? "" : `${STORAGE_URL}/bgm${_bgmUrl}`,
      };
    } else if (_bgmOption) {
      content = {
        bgmOption: _bgmOption,
      };
    } else if (_isAuto !== undefined) {
      content = {
        isAuto: _isAuto,
      };
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  const debounceSubmit = React.useCallback(
    debounce(async ({ _bgmUrl, _bgmOption, _isAuto }) => {
      let content = {};

      if (_bgmUrl !== undefined) {
        content = {
          bgmUrl: _bgmUrl === "" ? "" : `${STORAGE_URL}/bgm${_bgmUrl}`,
        };
      } else if (_bgmOption) {
        content = {
          bgmOption: _bgmOption,
        };
      } else if (_isAuto !== undefined) {
        content = {
          isAuto: _isAuto,
        };
      }

      await dbService
        .collection("newletters")
        .doc(letterId)
        .update(content)
        .then((res) => {
          pushData();
        });
    }, 1000),
    []
  );

  const pushData = () => {
    getData({ isSaved: true });
  };

  const onFileChange = (event, index) => {
    const {
      target: { files, name },
    } = event;

    const theFile = files[0];
    const size = files[0] ? files[0].size : 0;

    if (size < FILE_SIZE_MAX_LIMIT) {
      const reader = new FileReader();

      reader.onloadend = async (finishedEvent) => {
        // console.log(finishedEvent.target.result);
        const {
          currentTarget: { result },
        } = finishedEvent;

        console.log(theFile);
        uploadBgm(theFile);
      };
      theFile && reader.readAsDataURL(theFile);
    } else {
      alert("음원은 5MB 이하만 업로드 가능합니다");
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <Select
            value={selectedOption}
            onChange={bgmHandleChange}
            options={options}
            isSearchable={false}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#f2f1ff",
                primary: "#182e0c",
              },
            })}
          />

          <input
            id="mainImg"
            name="mainImg"
            type="file"
            accept="audio/mp3"
            onChange={onFileChange}
            ref={fileInput}
            style={{ width: 1, height: 1 }}
          />
        </div>

        {bgmUrl && (
          <div
            style={{
              width: 120,
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <>
              <audio ref={myRef} src={bgmUrl} />
              {isPlay ? (
                <div
                  onClick={pauseAudio}
                  style={{
                    height: 30,
                    width: 90,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      lineHeight: "12px",
                    }}
                  >
                    {isPlay ? "정지" : "재생"}
                  </span>
                  {isPlay ? (
                    <StopIcon
                      style={{
                        fontSize: 14,
                        paddingLeft: 3,
                      }}
                    />
                  ) : (
                    <PlayArrowIcon
                      style={{
                        fontSize: 14,
                        paddingLeft: 3,
                      }}
                    />
                  )}
                </div>
              ) : (
                <div
                  onClick={startAudio}
                  style={{
                    height: 30,
                    width: 90,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      lineHeight: "12px",
                    }}
                  >
                    {isPlay ? "정지" : "재생"}
                  </span>
                  {isPlay ? (
                    <StopIcon
                      style={{
                        fontSize: 14,
                        paddingLeft: 3,
                      }}
                    />
                  ) : (
                    <PlayArrowIcon
                      style={{
                        fontSize: 14,
                        paddingLeft: 3,
                      }}
                    />
                  )}
                </div>
              )}
            </>
          </div>
        )}
      </div>
      <div
        onClick={() => {
          setIsAuto((value) => {
            debounceSubmit({ _isAuto: !value });

            return !value;
          });
        }}
        style={{ paddingTop: 20 }}
      >
        <CheckboxComponent
          title="카카오톡으로 열람시 배경음악을 자동재생 합니다."
          isChecked={isAuto}
        />
      </div>
    </div>
  );
}
