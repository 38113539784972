import React, { forwardRef, useState, useCallback } from "react";
import Switch from "react-switch";
import Compressor from "compressorjs";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import DatePicker from "react-datepicker";
import { dbService } from "../../fbase";

import "react-datepicker/dist/react-datepicker.css";

import { ko } from "date-fns/esm/locale";
import { useEffect } from "react";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";
import { debounce } from "lodash";

import imgDesign1 from "../../assets/images/design-1.png";
import imgDesign2 from "../../assets/images/design-2.png";
import imgDesign3 from "../../assets/images/design-3.png";
import imgDesign4 from "../../assets/images/design-4.png";
import imgDesign99 from "../../assets/images/design-99.png";

export default function TemplateComponent({
  state,
  getData,
  letterId,
  getTemplate,
}) {
  const [template, setTemplate] = useState(state.template ? state.template : 0);
  const [isTrumpModal, setIsTrumpModal] = useState(false);

  const [colorVer, setColorVer] = useState(state.colorVer ? state.colorVer : 0);
  const [value, setValue] = useState("");

  const pushData = () => {
    getData({ isSaved: true });
  };

  const pushTemplate = (_template) => {
    getTemplate({ template: _template });
  };

  const debounceSubmit = useCallback(
    debounce(async ({ _template }) => {
      onSubmit({ _template });
    }, 500),
    []
  );

  const onSubmit = async ({ _template, _colorVer }) => {
    let content = {};
    if (_template !== undefined) {
      content = {
        template: _template,
      };
      // console.log("_template");
    } else if (_colorVer !== undefined) {
      content = {
        colorVer: _colorVer,
      };
      // console.log("_colorVer");
    }

    await dbService
      .collection("newletters")
      .doc(letterId)
      .update(content)
      .then((res) => {
        pushData();
      });
  };

  const handleVerifyCode = () => {
    const validCode = "TRUMP2024"; // 구매코드

    if (value.trim().toLowerCase() === validCode.toLowerCase()) {
      alert("✅ 구매코드 인증 완료! 메인사진에 전달받은 이미지를 추가하세요");
      debounceSubmit({ _template: 5 });
      setTemplate(5);
      pushTemplate(5);
      setIsTrumpModal(false); // 인증 성공 시 실행할 함수
    } else {
      alert("❌ 구매코드가 올바르지 않습니다. 다시 확인해주세요.");
    }
  };

  return (
    <div>
      <div>
        <div>
          <SubTitleComponent title="디자인" />
          <div style={{ display: "flex", overflow: "auto" }}>
            <div
              className="main-design-wrap"
              style={{
                border: template === 0 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 0 });
                setTemplate(0);
                pushTemplate(0);
              }}
            >
              <img src={imgDesign1} style={{ width: 120 }} />

              <div className="main-design-text">
                <b>type</b> 안나
              </div>
            </div>
            <div
              className="main-design-wrap"
              style={{
                border: template === 1 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 1 });
                setTemplate(1);
                pushTemplate(1);
              }}
            >
              <img src={imgDesign2} style={{ width: 120 }} />
              <div className="main-design-text">
                <b>type</b> 벨라
              </div>
            </div>
            <div
              className="main-design-wrap"
              style={{
                border: template === 2 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 2 });
                setTemplate(2);
                pushTemplate(2);
              }}
            >
              <img src={imgDesign3} style={{ width: 120 }} />
              <div className="main-design-text">
                <b>type</b> 메르디
              </div>
            </div>

            <div
              className="main-design-wrap"
              style={{
                border: template === 3 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 3 });
                setTemplate(3);
                pushTemplate(3);
              }}
            >
              <img src={imgDesign4} style={{ width: 120 }} />
              <div className="main-design-text">
                <b>type</b> 오브
              </div>
            </div>

            <div
              className="main-design-wrap"
              style={{
                border: template === 4 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 4 });
                setTemplate(4);
                pushTemplate(4);
              }}
            >
              <img
                src={
                  "https://storage.cryucard.com/image/2025-03-22/qUGK5rk3kCYYfMunY6vi-42f4ae97-2b05-40fd-94fe-248314d5f482.jpeg"
                }
                style={{ width: 120 }}
              />
              <div className="main-design-text">
                <b>type</b> 레트로
              </div>
            </div>

            <div
              className="main-design-wrap"
              style={{
                border: template === 5 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                // debounceSubmit({ _template: 5 });
                // setTemplate(5);
                // pushTemplate(5);
                setIsTrumpModal(true);
              }}
            >
              <img
                src={
                  "https://storage.cryucard.com/image/2025-01-27/9o7zDTGLhfXW2KzNEhGR-510e4e95-af4e-44bc-8698-57f37439d548"
                }
                style={{ width: 120 }}
              />
              <div className="main-design-text">
                <b>type</b> 트럼프카드
              </div>
            </div>

            <div
              className="main-design-wrap"
              style={{
                border: template === 99 ? "1px solid #d4d4d4" : "none",
              }}
              onClick={() => {
                debounceSubmit({ _template: 99 });
                setTemplate(99);
              }}
            >
              <img src={imgDesign99} style={{ width: 120 }} />
              <div className="main-design-text">
                <b>type</b> 무지
              </div>
            </div>
          </div>
        </div>

        {isTrumpModal === true && (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.4)",
              top: 0,
              left: 0,
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                width: "100%",
                maxWidth: 380,
                margin: 16,
                borderRadius: 15,
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  top: -45,
                  right: 0,
                  zIndex: 1000,
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsTrumpModal(false);
                }}
              >
                <CloseIcon />
              </div>

              <div
                style={{
                  padding: "0 16px 20px",
                  height: 300,
                }}
              >
                <div>
                  <div style={{ textAlign: "center" }}>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "30px 0 10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: 20,
                          height: 20,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: 12,
                          backgroundColor: "#724ff3",
                          borderRadius: 15,
                          fontWeight: "bold",
                          margin: "0 auto",
                          lineHeight: "12px",
                        }}
                      >
                        !
                      </div>

                      <div
                        style={{
                          fontSize: 12,
                          paddingTop: 5,
                        }}
                      >
                        해당 템플릿은{" "}
                        <b style={{ color: "#724ff3" }}>
                          실물 청첩장을 제작하신 분
                        </b>
                        만 <br />
                        사용할 수 있습니다.
                        <br />
                        <br />
                        이미 제작하셨다면 아래에 전달받은 <br />
                        <b style={{ color: "#724ff3" }}>구매코드</b>를
                        입력해주세요
                      </div>
                    </div>

                    <div style={{ paddingTop: 20 }}>
                      <div
                        style={{
                          border: "1px solid",
                          borderColor: "#e5e4e4",
                          borderRadius: 8,
                          overflow: "hidden",
                          padding: "0 8px",
                          backgroundColor: "#fefcfc",
                          position: "relative",
                          display: "flex",
                          width: 244,
                          margin: "0 auto",
                        }}
                      >
                        <input
                          placeholder={"구매코드"}
                          onChange={(e) => {
                            // console.log(e.target.value);
                            setValue(e.target.value);
                          }}
                          value={value}
                          style={{
                            width: "100%",
                            height: 45,
                            border: "none",
                            fontSize: "1rem",
                            backgroundColor: "#fefcfc",
                          }}
                          class="prod-text-input"
                          autocomplete="off"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          backgroundColor: "#724ff3",
                          width: 260,
                          height: 45,
                          cursor: "pointer",
                          margin: "0 auto",
                          borderRadius: 10,
                          marginTop: 10,
                          fontSize: 14,
                        }}
                        onClick={() => {
                          handleVerifyCode();
                        }}
                      >
                        확인
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <SubTitleComponent title="청첩장 컬러" />
          <div style={{ display: "flex", overflow: "auto" }}>
            <div
              className={`prod-color-picker-wrap ${
                colorVer === 0 ? "prod-color-picker-wrap-click" : ""
              }`}
              onClick={() => {
                onSubmit({ _colorVer: 0 });
                setColorVer(0);
              }}
            >
              <div className="prod-color-picker-border ">
                <div
                  className="prod-color-picker-content"
                  style={{
                    backgroundColor: "#515a15",
                  }}
                ></div>
              </div>
            </div>

            <div
              className={`prod-color-picker-wrap ${
                colorVer === 1 ? "prod-color-picker-wrap-click" : ""
              }`}
              onClick={() => {
                onSubmit({ _colorVer: 1 });
                setColorVer(1);
              }}
            >
              <div className="prod-color-picker-border">
                <div
                  className="prod-color-picker-content"
                  style={{
                    backgroundColor: "#b87e5a",
                  }}
                ></div>
              </div>
            </div>

            <div
              className={`prod-color-picker-wrap ${
                colorVer === 2 ? "prod-color-picker-wrap-click" : ""
              }`}
              onClick={() => {
                onSubmit({ _colorVer: 2 });
                setColorVer(2);
              }}
            >
              <div className="prod-color-picker-border">
                <div
                  className="prod-color-picker-content"
                  style={{
                    backgroundColor: "#d09199",
                  }}
                ></div>
              </div>
            </div>

            <div
              className={`prod-color-picker-wrap ${
                colorVer === 3 ? "prod-color-picker-wrap-click" : ""
              }`}
              onClick={() => {
                onSubmit({ _colorVer: 3 });
                setColorVer(3);
              }}
            >
              <div className="prod-color-picker-border">
                <div
                  className="prod-color-picker-content"
                  style={{
                    backgroundColor: "#ad9f91",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
