import React from "react";
import { useState, useEffect } from "react";
import colors from "../../themes/colors";
import arrow from "../../assets/images/next.svg";
import share from "../../assets/images/share.svg";
import trash from "../../assets/images/trash.svg";
import qr from "../../assets/images/qr-code.png";
import SubTitleComponent from "../SubTitleComponent/SubTitleComponent";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import TextInputPhoneComponent from "../TextInputPhoneComponent/TextInputPhoneComponent";
import axios from "axios";
import { dbService } from "../../fbase";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ReactHtmlParser from "react-html-parser";
import QrCodeComponent from "../QrCodeComponent/QrCodeComponent";

export default function RenewalCardComponent({ user, index }) {
  const [isWaterMark, setIsWaterMark] = useState("0"); //0 워터마크 미제거, 1 워터마크 제거, 2 영구보존 구매
  const [naverEmail, setNaverEmail] = useState("");
  const [coupon, setCoupon] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [modalPage, setModalPage] = useState("0");

  const deleteLetter = async (letterId) => {
    var confirm = window.confirm(
      "정말 삭제하시겠습니까? 삭제 후 복구가 불가합니다"
    );

    if (confirm) {
      var letterRef = dbService.collection("newletters");
      // await letterRef
      //     .doc(letterId)
      //     .delete()
      //     .then((res) => {
      //         alert("제작하신 청첩장이 삭제 되었습니다");
      //         window.location.href = "/#/orderlist";
      //     });

      await letterRef
        .doc(letterId)
        .update({
          weddingDate: "2020-01-01",
          expire: false,
        })
        .then((res) => {
          alert("제작하신 청첩장이 삭제 되었습니다");
          window.location.reload();
        });
    } else {
    }
  };

  const copyLetter = async () => {
    await dbService
      .collection("newletters")
      .add(user)
      .then((res) => {
        console.log(res.id);
      });
  };

  const transDate = ({ weddingDate, weddingTime }) => {
    var weekday = new Array();
    weekday[0] = "일요일";
    weekday[1] = "월요일";
    weekday[2] = "화요일";
    weekday[3] = "수요일";
    weekday[4] = "목요일";
    weekday[5] = "금요일";
    weekday[6] = "토요일";

    var date = "";
    var year = "";
    var month = "";
    var day = "";
    var hours = "";
    var min = "";
    var week = "";

    if (weddingDate) {
      date = new Date(weddingDate + "T09:00:00Z");
      year = date.getFullYear();
      month = date.getMonth() + 1 + "월";
      day = date.getDate() + "일";
      week = date.getDay();
    }
    if (weddingTime) {
      hours =
        weddingTime.slice(0, 2) === "24" || weddingTime.slice(0, 2) === "12"
          ? "낮 12시"
          : weddingTime.slice(0, 2) > 12
          ? " 오후 " + (weddingTime.slice(0, 2) * 1 - 12) + "시"
          : " 오전 " + weddingTime.slice(0, 2) * 1 + "시";

      min =
        weddingTime.slice(3, 5) === "00" ? "" : weddingTime.slice(3, 5) + "분";
    }

    return `${month} ${day} ${weekday[week]} ${hours}${min}`;
  };

  const getText = (data) => {
    // console.log(data);

    const { text, type } = data;

    switch (type) {
      case "naverEmail":
        setNaverEmail(text);
        break;

      case "waterMarkCoupon":
        setCoupon(text);
        break;

      default:
        break;
    }
  };

  const getPhoneNumber = (data) => {
    setPhoneNumber(data.phoneNumber);
  };

  const urlReturn = (letterId) => {
    let url = "";

    url = `https://mcard.cryucard.com/c/${letterId}`;

    return url;
  };

  const urlReturnForKakao = (letterId, letterVer) => {
    let url = "";

    url = `https://mcard.cryucard.com/c/${letterId}`;

    return url;
  };

  const getBuyList = (email, letterId) => {
    var buyListRef = dbService.collection("newbuyList");
    var letterRef = dbService.collection("newletters");
    // console.log("func getBuyList");

    if (phoneNumber.length === 0 || phoneNumber.length > 9) {
      if (email) {
        buyListRef
          .where("userEmail", "==", email)
          .get()
          .then(async (res) => {
            if (!res.empty) {
              // console.log(res);

              await Promise.all(
                res.docs.map(async (item) => {
                  var data = item.data();
                  data.id = item.id;
                  // console.log(data);

                  await letterRef.doc(letterId).update({
                    waterMark: true,
                    waterMarkCode: email,
                    expire: data.expire ? data.expire : false,
                  });

                  if (data.quantity > 1) {
                    await buyListRef
                      .doc(item.id)
                      .update({
                        quantity: data.quantity - 1,
                      })
                      .then(async () => {
                        if (phoneNumber.length !== 0) {
                          await axios.get(
                            `https://us-central1-self-cryucard.cloudfunctions.net/api/kakao/a1?url=${urlReturnForKakao(
                              user.id
                            )}&phoneNumber=${phoneNumber}&expire=${
                              data.expire
                                ? "유효기간 없이"
                                : "예식일 이후 30일 까지"
                            }&modify=${"mypage"}`
                          );
                        }

                        await alert("워터마크가 제거 되었습니다!");
                        await window.location.reload();
                      });
                  } else {
                    await buyListRef
                      .doc(item.id)
                      .delete()
                      .then(async () => {
                        if (phoneNumber.length !== 0) {
                          await axios.get(
                            `https://us-central1-self-cryucard.cloudfunctions.net/api/kakao/a1?url=${urlReturnForKakao(
                              user.id
                            )}&phoneNumber=${phoneNumber}&expire=${
                              data.expire
                                ? "유효기간 없이"
                                : "예식일 이후 30일 까지"
                            }&modify=${"mypage"}`
                          );
                        }

                        await alert("워터마크가 제거 되었습니다!");
                        await window.location.reload();
                      })
                      .catch((error) => {
                        console.error("Error removing document: ", error);
                      });
                  }
                })
              );
            } else {
              alert("구매내역이 없습니다.");
              setIsLoading(false);
            }
          });
      } else {
        alert("구매 후 네이버 아이디를 입력하여주세요");
        setIsLoading(false);
      }
    } else {
      alert("연락처를 다시 한 번 확인해주세요");
      setIsLoading(false);
    }
  };

  const getCouponList = (couponNumber, letterId) => {
    var couponRef = dbService.collection("newcouponList");
    var letterRef = dbService.collection("newletters");
    console.log("func getBuyList");
    if (couponNumber) {
      couponRef
        .where("couponNumber", "==", couponNumber)
        .get()
        .then(async (res) => {
          if (!res.empty) {
            // console.log(res);

            await Promise.all(
              res.docs.map(async (item) => {
                var data = item.data();
                data.id = item.id;
                // console.log(data);

                await letterRef.doc(letterId).update({
                  waterMark: true,
                  waterMarkCode: couponNumber,
                  expire: data.expire ? data.expire : false,
                });

                if (data.quantity > 1) {
                  await couponRef
                    .doc(item.id)
                    .update({ quantity: data.quantity - 1 })
                    .then(async () => {
                      if (phoneNumber.length !== 0) {
                        await axios.get(
                          `https://us-central1-self-cryucard.cloudfunctions.net/api/kakao/a1?url=${urlReturnForKakao(
                            user.id
                          )}&phoneNumber=${phoneNumber}&expire=${
                            data.expire
                              ? "유효기간 없이"
                              : "예식일 이후 30일 까지"
                          }&modify=${"mypage"}`
                        );
                      }
                      await alert("워터마크가 제거 되었습니다!");
                      await window.location.reload();
                    });
                } else {
                  await couponRef
                    .doc(item.id)
                    .delete()
                    .then(async () => {
                      if (phoneNumber.length !== 0) {
                        await axios.get(
                          `https://us-central1-self-cryucard.cloudfunctions.net/api/kakao/a1?url=${urlReturnForKakao(
                            user.id
                          )}&phoneNumber=${phoneNumber}&expire=${
                            data.expire
                              ? "유효기간 없이"
                              : "예식일 이후 30일 까지"
                          }&modify=${"mypage"}`
                        );
                      }
                      await alert("워터마크가 제거 되었습니다!");
                      await window.location.reload();
                    })
                    .catch((error) => {
                      console.error("Error removing document: ", error);
                      setIsLoading(false);
                    });
                }
              })
            );
          } else {
            alert("쿠폰번호가 일치하지 않습니다.");
            setIsLoading(false);
          }
        });
    } else {
      alert("쿠폰번호를 입력하여주세요");
      setIsLoading(false);
    }
  };

  const imageUrl =
    user.template === 5
      ? user.kakaoThumb || user.trumpVer1Img
      : user.template === 6
      ? user.kakaoThumb || user.trumpVer2Img
      : user.kakaoThumb || user.mainImg;

  const KakaoThumbnail = () => {
    return (
      <a
        href={urlReturn(user.id)}
        target="_blank"
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          style={{
            width: 216,
            borderRadius: 10,
            overflow: "hidden",
            boxShadow: "0px 5px 10px rgb(0 0 0 / 18%)",
          }}
        >
          <div
            style={{
              width: 216,
              height: 324,
              backgroundColor: "#e0e0e0",
            }}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                style={{
                  width: 216,
                  height: 324,
                  objectFit: "cover",
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div style={{ fontSize: 12 }}>메인사진을 업로드해주세요</div>
              </div>
            )}
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              height: "100%",
              padding: "10px 10px",
            }}
          >
            <div style={{ fontSize: 14, color: "#333" }}>
              {user.kakaoTitle
                ? user.kakaoTitle
                : `${user.hName ? user.hName : "신랑"}❤️${
                    user.wName ? user.wName : "신부"
                  } 결혼합니다.`}
            </div>

            <div
              style={{
                fontSize: 12,
                marginTop: 3,
                color: colors.GRAY_COLOR,
              }}
            >
              {user.kakaoContent
                ? ReactHtmlParser(user.kakaoContent)
                : transDate({
                    weddingDate: user.weddingDate,
                    weddingTime: user.weddingTime,
                  })}
            </div>

            <div
              style={{
                backgroundColor: colors.LINE_COLOR,
                fontSize: 13,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
                borderRadius: 5,
                color: "#333",
              }}
            >
              모바일 청첩장
            </div>

            <div
              style={{
                fontSize: 10,
                marginTop: 5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: colors.GRAY_COLOR,
              }}
            >
              <span>크류카드</span>
              <img src={arrow} style={{ width: 10, height: 10 }} />
            </div>
          </div>
        </div>
      </a>
    );
  };

  const Page0 = () => {
    return (
      <div>
        <div
          style={{
            color: "#333",
            marginTop: "2rem",
            borderTop: "1px dashed #999",
            paddingTop: "1rem",
          }}
        >
          <div>
            <SubTitleComponent
              title="구매한 네이버 아이디 "
              type="waterMarkId"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div style={{ maxWidth: 120 }}>
                <TextInputComponent getText={getText} type="naverEmail" />
              </div>
              <span style={{ marginLeft: 5 }}>@naver.com</span>
            </div>
          </div>

          <div>
            <SubTitleComponent title="전달받을 연락처" />

            <TextInputPhoneComponent
              getPhoneNumber={getPhoneNumber}
              type="waterMarkPhoneNumber"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: 200,
            height: 45,
            backgroundColor: "#182e0c",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            borderRadius: 8,
            margin: "3rem auto 0",
            cursor: "pointer",
          }}
          onClick={() => {
            if (!isLoading) {
              setIsLoading(true);
              getBuyList(naverEmail, user.id);
            }
          }}
        >
          워터마크 제거
        </div>
      </div>
    );
  };

  const Page1 = () => {
    return (
      <div>
        <div
          style={{
            color: "#333",
            marginTop: "2rem",
            borderTop: "1px dashed #999",
            paddingTop: "1rem",
          }}
        >
          <div>
            <SubTitleComponent title="쿠폰번호" />

            <TextInputComponent type="waterMarkCoupon" getText={getText} />
          </div>

          <div>
            <SubTitleComponent title="전달받을 연락처" />
            <TextInputPhoneComponent
              getPhoneNumber={getPhoneNumber}
              type="waterMarkPhoneNumber"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: 200,
            height: 45,
            backgroundColor: "#182e0c",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            borderRadius: 8,
            margin: "3rem auto 0",
            cursor: "pointer",
          }}
          onClick={() => {
            if (!isLoading) {
              setIsLoading(true);
              getCouponList(coupon, user.id);
            }
          }}
        >
          워터마크 제거
        </div>
      </div>
    );
  };

  const initKakaoShare = async () => {
    // console.log(window.Kakao.Link);
    await window.Kakao.Link.cleanup();

    await window.Kakao.Link.createDefaultButton({
      container: `#kakao-link-btn${index}`,
      objectType: "feed",
      content: {
        title: user.kakaoTitle
          ? user.kakaoTitle
          : `${user.hName ? user.hName : "신랑"}❤️${
              user.wName ? user.wName : "신부"
            } 결혼합니다.`,
        description: user.kakaoContent
          ? user.kakaoContent.split("<br/>").join("\n")
          : transDate({
              weddingDate: user.weddingDate,
              weddingTime: user.weddingTime,
            }),
        imageUrl:
          user.template === 5
            ? user.kakaoThumb || user.trumpVer1Img
            : user.template === 6
            ? user.kakaoThumb || user.trumpVer2Img
            : user.kakaoThumb || user.mainImg,
        imageHeight: 800,
        link: {
          mobileWebUrl: urlReturn(user.id),
          webUrl: urlReturn(user.id),
          androidExecParams: "invite",
        },
      },
      buttons: [
        {
          title: "청첩장 보기",
          link: {
            mobileWebUrl: urlReturn(user.id),
            webUrl: urlReturn(user.id),
          },
        },
      ],
    });
  };

  // React.useEffect(() => {
  //     if (window.Kakao) {
  //         if (window.Kakao.isInitialized() === false) {
  //             window.Kakao.init("ceac62315cf4bfcb9030a1c46642f4ca");
  //         }
  //     }
  // }, []);

  return (
    <div>
      <div style={{ position: "relative", marginBottom: 20 }}>
        <div
          style={{
            width: "100%",
            boxShadow: "0px 5px 8px rgb(0 0 0 / 18%)",
            overflow: "hidden",
            borderRadius: 10,
          }}
        >
          <div
            style={{
              height: 500,
              backgroundColor: colors.BG_COLOR,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <KakaoThumbnail />
            <div
              style={{
                position: "absolute",
                left: 10,
                top: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: 40,
                  height: 40,
                  borderRadius: 25,
                  backgroundColor: colors.EMPHASIS_COLOR,
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  deleteLetter(user.id);
                }}
              >
                <img src={trash} style={{ width: 20, height: 20 }} />
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                right: 10,
                bottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: 40,
                  height: 40,
                  borderRadius: 25,
                  backgroundColor: user.mainImg ? "#333333" : "#999",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (user.mainImg || user.kakaoThumb) {
                    initKakaoShare();
                  } else {
                    alert("카카오톡 썸네일을 추가해주세요");
                  }
                }}
                id={`kakao-link-btn${index}`}
              >
                <img src={share} style={{ width: 18, height: 18 }} />
              </div>

              <div
                style={{
                  display: "flex",
                  width: 40,
                  height: 40,
                  borderRadius: 25,
                  backgroundColor: "#333333",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                }}
                onClick={() => {
                  setIsModal(true);
                  setModalPage("2");
                }}
              >
                <img src={qr} style={{ width: 25, height: 25 }} />
              </div>

              {/* <div
                                style={{
                                    marginTop: 10,
                                    display: "flex",
                                    width: 40,
                                    height: 40,
                                    borderRadius: 25,
                                    backgroundColor: "#000",
                                    cursor: "pointer",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                onClick={() => {
                                    copyLetter();
                                }}
                            ></div> */}
            </div>
          </div>

          <div
            style={{
              padding: "15px 10px",
              backgroundColor: "#fff",
              position: "relative",
              zIndex: 1000,
            }}
          >
            <div style={{ display: "flex" }}>
              <Link
                to={`/product/${user.id}`}
                style={{ textDecoration: "none", flex: 1 }}
              >
                <div
                  style={{
                    height: 45,
                    flex: 1,
                    backgroundColor: colors.GRAY_COLOR,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 6,
                    color: "#fff",
                  }}
                >
                  수정하기
                </div>
              </Link>
              <Link
                to={{
                  pathname: `/newsurvey/${user.id}`,
                }}
                style={{ textDecoration: "none", flex: 1 }}
              >
                <div
                  style={{
                    height: 45,
                    flex: 1,
                    backgroundColor: colors.KEY_COLOR,
                    marginLeft: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 6,
                    color: "#fff",
                  }}
                >
                  분석보기
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor:
              user.waterMark === false
                ? colors.EMPHASIS_COLOR
                : user.expire === true
                ? colors.POINT_COLOR_3
                : colors.POINT_COLOR_1,
            color: "#fff",
            padding: "10px 0 5px",
            fontSize: "0.9rem",
            position: "relative",
            bottom: 5,
            borderRadius: "0 0 10px 10px ",
            cursor: "pointer",
          }}
          onClick={() => {
            if (user.waterMark === false) {
              setIsModal(true);
              setModalPage("0");
            }
          }}
          data-tip={
            user.waterMark === false
              ? ""
              : user.expire === true
              ? "유효기간 없이 평생보관 됩니다."
              : "유효기간 : 예식일 이후 30일까지"
          }
        >
          {user.waterMark === false
            ? "워터마크 제거하기"
            : user.expire === true
            ? "평생보관 서비스 적용 중"
            : "워터마크 제거 완료"}
        </div>

        <ReactTooltip place="bottom" />
      </div>

      {/* 모달창 */}
      {isModal ? (
        <div>
          <div
            style={{
              position: "fixed",
              backgroundColor: "rgba(20, 20, 20, 0.8)",
              width: "100%",
              height: "100%",
              zIndex: 9999,
              top: 0,
              left: 0,
              right: 0,
              letterSpacing: "0.5px",
            }}
            onClick={() => {
              setIsModal(false);
            }}
          ></div>

          {modalPage === "0" ? (
            <div
              style={{
                maxWidth: 340,
                maxHeight: 550,
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                backgroundColor: colors.BG_COLOR,
                borderRadius: 10,
                zIndex: 10000,
              }}
            >
              <div style={{ padding: "2rem 1rem" }}>
                <div style={{ color: "#333" }}>
                  <p
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    워터마크 제거
                  </p>

                  <p style={{ fontSize: "0.8rem" }}>
                    워터마크를 제거하시려면{" "}
                    <a
                      href="https://smartstore.naver.com/cryu/products/5209453737"
                      target="_blank"
                      style={{
                        color: "#333",
                        fontWeight: "bold",
                      }}
                    >
                      네이버 스마트스토어
                    </a>{" "}
                    내에서
                    <br /> 구매 후 아이디를 입력해주세요
                  </p>
                  <p
                    style={{
                      fontSize: "0.8rem",
                      color: "#999",
                    }}
                  >
                    (구매 확인까지 <b>최대 20분</b>이 소요될 수 있습니다.)
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "2rem",
                  }}
                >
                  <div
                    onClick={() => {
                      setPageNum(0);
                      setNaverEmail("");
                      setCoupon("");
                    }}
                    className="prod-button"
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: pageNum == 0 ? "#182e0c" : "#fff",
                      color: pageNum == 0 ? "#fff" : "#333",
                    }}
                  >
                    아이디 입력
                  </div>
                  <div
                    onClick={() => {
                      setPageNum(1);
                      setNaverEmail("");
                      setCoupon("");
                    }}
                    className="prod-button"
                    style={{
                      flex: 1,
                      marginLeft: 8,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: pageNum == 1 ? "#182e0c" : "#fff",
                      color: pageNum == 1 ? "#fff" : "#333",
                    }}
                  >
                    쿠폰 입력
                  </div>
                </div>

                {pageNum == 0 ? Page0() : Page1()}
              </div>
            </div>
          ) : modalPage === "1" ? (
            <div
              style={{
                maxWidth: 340,
                maxHeight: 550,
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                backgroundColor: colors.WHITE_COLOR,
                borderRadius: 10,
                zIndex: 10000,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: 45,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.BG_COLOR,
                  fontSize: 14,
                  borderBottom: `1px solid ${colors.LINE_COLOR}`,
                }}
              >
                청첩장 전송하기
              </div>
              <div style={{ padding: "20px 16px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: colors.KEY_COLOR,
                      color: colors.WHITE_COLOR,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      fontWeight: "bold",
                    }}
                  >
                    1
                  </div>

                  <div>
                    청첩장 내에서 최하단까지 스크롤한 후 우측 하단의 공유하기
                    버튼을 클릭해주세요
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 50,
                    fontSize: 14,
                  }}
                >
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: colors.KEY_COLOR,
                      color: colors.WHITE_COLOR,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      fontWeight: "bold",
                    }}
                  >
                    2
                  </div>

                  <div>
                    카카오톡 공유 버튼을 클릭 후 전송을 희망하는 분에게
                    전송해주세요
                  </div>
                </div>
              </div>
            </div>
          ) : modalPage === "2" ? (
            <div
              style={{
                maxWidth: 340,
                maxHeight: 550,
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                backgroundColor: colors.WHITE_COLOR,
                borderRadius: 10,
                zIndex: 10000,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: 45,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.BG_COLOR,
                  fontSize: 14,
                  borderBottom: `1px solid ${colors.LINE_COLOR}`,
                }}
              >
                큐알코드 다운로드
              </div>

              <div style={{ height: 505 }}>
                <QrCodeComponent url={urlReturnForKakao(user.id)} />
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      ) : (
        false
      )}

      {/* 모달창 */}
    </div>
  );
}
